$positionYEnter: 8%;
$positionYExit: 0;
$duration: var(--transition-duration);

.select-animation {
  &-enter {
    opacity: 0;
    transform: translateY($positionYEnter);

    &-active {
      opacity: 1;
      transform: translateY($positionYExit);
      transition: transform $duration ease, opacity $duration ease;
    }

    &-done {
      transform: translateY($positionYExit);
    }
  }

  &-exit {
    opacity: 1;
    transform: translateY($positionYExit);
    &-active {
      opacity: 0;
      transform: translateY($positionYEnter);
      transition: transform $duration ease, opacity $duration ease;
    }
  }
}
