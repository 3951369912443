@import '../../../../../assets/styles/_default/variables';
@import '../../../../../assets/styles/_mixins/breakpoint';
@import '../../../../../assets/styles/_functions/convert-px2rem';

$max-width: convert-px2rem(600);
$header__font-size: convert-px2rem(40);
$font-size: convert-px2rem(34);
$padding: convert-px2rem(8) convert-px2rem(5);


.info-window-content {
  max-width: $max-width;
  text-align: center;
  padding: $padding;
  line-height: 1.3;

  &__header {
    font-weight: 500;
    font-size: $header__font-size;
    margin-bottom: convert-px2rem(15);
  }

  &__address {
    font-size: $font-size;
  }
}
