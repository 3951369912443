.t {
  &-uppercase {
    text-transform: uppercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}
