@import '_default/variables';
@import './_mixins/breakpoint';
@import './_functions/convert-px2rem';
@import './_functions/convert-px2vmin';

* {
  box-sizing: border-box;
  outline: none !important;
  font-size: 1rem;
}

html {
  font-size: var(--font-size__mobile);
  @include breakpoint($b-mobileS) {
    font-size: var(--font-size__mobileS);
  }

  @include breakpoint($b-tablet) {
    font-size: var(--font-size__tablet);
  }

  @include breakpoint($b-tabletS) {
    font-size: var(--font-size__tabletS);
  }

  @include breakpoint($b-laptop) {
    font-size: var(--font-size__laptop);
  }

  @include breakpoint($b-desktop) {
    font-size: var(--font-size__desktop);
  }

  @include breakpoint($b-4k) {
    font-size: var(--font-size__4k);
  }
}

body {
  webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color__gray_dark);
  font-family: var(--font__primary);
  color: var(--color__gray_light);
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.scrollbar {
  &__thumb {
    background-color: #383838;
    border-radius: convert-px2rem(6);
  }
}
