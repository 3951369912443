@import '../../../assets/styles/_default/variables';
@import '../../../assets/styles/_mixins/breakpoint';
@import '../../../assets/styles/_functions/convert-px2rem';

.gm {
  &-style {
    background-color: var(--color__gray_dark);

    & > div {
      a {
        img {
          margin-top: convert-px2rem(-70) !important;
          margin-left: 10px !important;
          @include breakpoint($b-laptop) {
            margin-top: -10px !important;
            margin-left: 5px !important;
          }
        }
      }

      & > .gmnoprint, & > .gmnoscreen, & > .gm-style-cc {
        margin-bottom: 5px !important;
        transform: translateX(-5px) !important;
      }
    }
  }
}
