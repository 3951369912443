@import '../../../../assets/styles/_default/variables';
@import '../../../../assets/styles/_mixins/breakpoint';
@import '../../../../assets/styles/_functions/convert-px2rem';
@import '../../../../assets/styles/_functions/convert-px2vmin';

.main-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5000;
  pointer-events: none;
  @include breakpoint($b-laptop) {
    transform: scale(.55);
  }

  .site-logo {
    position: absolute;
    display: block;
    z-index: 5;
    left: convert-px2vmin(42);
    top: convert-px2vmin(96);
    width: convert-px2vmin(450);
    max-width: none;
  }

  &__decoration {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 172%;
    background-position: convert-px2vmin(123) convert-px2vmin(-141);
    transform: rotate(45deg);
    width: convert-px2vmin(935);
    height: convert-px2vmin(1325);
    transform-origin: 100% 0;
  }

  &__point {
    position: absolute;
    display: block;
    left: convert-px2vmin(40);
    top: convert-px2vmin(320);
    width: convert-px2vmin(150);
    z-index: 5;
  }
}
