@import '../../../assets/styles/_default/variables';
@import '../../../assets/styles/_mixins/breakpoint';
@import '../../../assets/styles/_functions/convert-px2rem';

.preload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: var(--color__gray_dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Tahoma, Geneva', sans-serif;

  &__text {
    font-size: convert-px2rem(50);
  }

  &__logo {
    animation: pulse .5s infinite ease-in-out alternate;

    img {
      width: convert-px2rem(400);
    }
  }
}

@keyframes pulse {
  from { transform: scale(.95); }
  to { transform: scale(1.05); }
}
