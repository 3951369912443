:root {
  --color__gray_dark: #272727;
  --color__gray_light: #F5F5F7;
  --color__orange_light: #FA6E55;

  --color__primary: var(--color__gray_dark);

  --font__ECircular-Light: 'ECircular-Light', sans-serif;
  --font__ECircular-Regular: 'ECircular-Regular', sans-serif;
  --font__ECircular-Medium: 'ECircular-Medium', sans-serif;
  --font__ECircular-Bold: 'ECircular-Bold', sans-serif;
  --font__ECircular-SemiBold: 'ECircular-SemiBold', sans-serif;

  --font__primary: var(--font__ECircular-Light);

  --font-size__mobile: 5px;
  --font-size__mobileS: 7px;
  --font-size__tablet: 8px;
  --font-size__tabletS: 7px;
  --font-size__laptop: 7px;
  --font-size__desktop-int: 14;
  --font-size__desktop: 6px;
  --font-size__4k: 9px;

  --transition-duration: 300ms;
}
