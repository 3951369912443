@import '../../../../../assets/styles/_functions/convert-px2rem';

$min-width: convert-px2rem(250);
$padding: convert-px2rem(10) convert-px2rem(15) convert-px2rem(15);
$border-radius: convert-px2rem(12);
$border-width: 2px;
$out-offset: convert-px2rem(8);

.gm {
  &-ui {
    &-hover-effect {
      display: none !important;
    }
  }

  &-style {
    div[aria-label='Map'] {
      div:first-child {
        div {
          div {
            img {
              left: 50% !important;
              top: 50% !important;
              transform: translate(-50%, -50%) !important;
              transition: opacity .5s ease, width .5s ease, height .5s ease;
            }
          }
        }
      }
    }

    &-iw {
      &-c {
        padding: $padding !important;
        color: #FFFFFF !important;
        background-color: var(--color__gray_dark) !important;
        border: $border-width solid var(--color__orange_light) !important;
        border-radius: $border-radius !important;
        min-width: $min-width !important;
        box-shadow: none !important;
      }

      &-t {
        margin-bottom: $out-offset !important;

        &::after {
          background: var(--color__orange_light) !important;
          z-index: -1 !important;
          box-shadow: none !important;
        }
      }

      &-d {
        overflow: auto !important;
      }
    }
  }
}
