@import '../../../assets/styles/_default/variables';
@import '../../../assets/styles/_mixins/breakpoint';
@import '../../../assets/styles/_functions/convert-px2rem';

.main-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .select {
    position: absolute;
    right: convert-px2rem(30);
    bottom: convert-px2rem(80);
    z-index: 1000;
    @include breakpoint($b-laptop) {
      right: convert-px2rem(100);
      bottom: convert-px2rem(150);
    }
  }
}
