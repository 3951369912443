@import '../../../../../assets/styles/_default/variables';
@import '../../../../../assets/styles/_mixins/breakpoint';
@import '../../../../../assets/styles/_functions/convert-px2rem';

$background-color: #1d1f1c;

.select {
  letter-spacing: convert-px2rem(4);
  position: relative;

  &_opened {
    .select {
      &__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__value {
    text-align: center;
    color: var(--color__gray_light);
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: convert-px2rem(54);
    min-width: convert-px2rem(542);
    max-width: convert-px2rem(600);
    min-height: convert-px2rem(108);
    padding: convert-px2rem(12) convert-px2rem(75) convert-px2rem(12) convert-px2rem(40);
    border: convert-px2rem(2) solid #383838;
  }

  &__selected {
    flex-grow: 1;
    font-size: convert-px2rem(38);
  }

  &__icon {
    transform: rotate(45deg);
    min-width: convert-px2rem(42);
    width: convert-px2rem(42);
    height: convert-px2rem(42);
    border-top: convert-px2rem(3) solid var(--color__gray_light);
    border-left: convert-px2rem(3) solid var(--color__gray_light);
  }

  &__arrow {
    position: relative;
    transition: transform var(--transition-duration) ease;
    transform-origin: 50% 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: convert-px2rem(25);
    margin-top: convert-px2rem(18);
    min-width: convert-px2rem(70);
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $background-color;
    overflow: hidden;
    margin-bottom: convert-px2rem(108 + 15);
    border-radius: convert-px2rem(34);
    border: convert-px2rem(2) solid #383838;
  }

  &__option {
    width: 100%;
    color: var(--color__gray_light);
    transition: background-color var(--transition-duration) ease, color var(--transition-duration) ease;
    padding: convert-px2rem(20);

    &:not(.select__option_selected):hover {
      @include breakpoint($b-laptop) {
        background-color: #292828;
      }
    }

    &:not(:last-child) {
      border-bottom: convert-px2rem(2) solid #373737;
    }

    &_selected {
      cursor: default;
      color: var(--color__orange_light);
      font-family: var(--font__ECircular-Medium);
    }
  }

  button {
    background-color: $background-color;
    text-transform: uppercase;
    font-size: convert-px2rem(38);
  }
}
