.fade-200-animation {
  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity 200ms ease !important;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0 !important;
      transition: opacity 200ms ease !important;
      pointer-events: none;
    }
  }
}
