button {
  border: none;
  display: block;
  padding: 0;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0;
  text-decoration: none;
  backface-visibility: hidden;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent !important;
}
